exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-board-of-directors-tsx": () => import("./../../../src/pages/board-of-directors.tsx" /* webpackChunkName: "component---src-pages-board-of-directors-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-templates-article-post-tsx": () => import("./../../../src/templates/article-post.tsx" /* webpackChunkName: "component---src-templates-article-post-tsx" */)
}

